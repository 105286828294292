import React, { useState, useEffect } from 'react'

import { useForm } from "react-hook-form";
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import styles from './Calculator.scss'
import firebase from '../../firebase'


const Form = (props) => {
  const { register, handleSubmit } = useForm()

  const {onSubmit, coins} = props

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="table p-8">
        <div className="head">
          <ul className="titles flex">
            <li className='w-20'>Moeda</li>
            <li>Valor</li>
          </ul>
        </div>
        
        <div className="body">
          <ul className="values">
            {coins.map((item)=> (
              <li key={item.name} className='flex text-sm mt-2 items-center'>
                <div className="w-8 h-8 bg-gray-500 rounded-full text-white flex items-center justify-center mr-8">{item.name.toUpperCase()}</div>
                <input className='focus:ring-2 focus:ring-blue-500 focus:outline-none text-sm leading-6 text-gray-900 placeholder-gray-400 rounded-md py-2 pl-4 ring-1 ring-gray-200 shadow-sm' defaultValue={item.value} {...register(item.name)} />
              </li>
            ))}
          </ul>
        </div>

        <button className='p-2 block px-3 py-2 rounded-md bg-sky-500 text-white' type="submit">Atualizar</button>
      </div>
    </form>
  )
}

export default function CalculatorFair(props) {
  const [costs, setCosts] = useState([]);
  const [margins, setMargins] = useState([]);

  function getCoinByCompanyAndCollection(company, collection) {
    return firebase.db
    .collection('calculator')
    .doc(company)
    .collection(collection)
  }

  useEffect(()=> {
    const costsCoins = getCoinByCompanyAndCollection('fair', 'costs')
    const marginsCoins = getCoinByCompanyAndCollection('fair', 'margins')

    costsCoins
    .doc('coins')
    .get()
    .then((res)=> {
      const entries = Object.entries(res.data())

      let collection = []

      entries.map((item)=> {
        collection.push({
          name: item[0],
          value: item[1]
        })
      })

      setCosts(collection)
    })

    marginsCoins
    .doc('coins')
    .get()
    .then((res)=> {
      if(res.data()) {
        const entries = Object.entries(res.data())
  
        let collection = []
  
        entries.map((item)=> {
          collection.push({
            name: item[0],
            value: item[1]
          })
        })
  
        setMargins(collection)
      }
    })
  }, [])



  const onSubmitCosts = data => {
    firebase.db
    .collection('calculator')
    .doc('fair')
    .collection('costs')
    .doc('coins')
    .update(data)

    alert('Atualizado com sucesso')
  }

  const onSubmitMargin = data => {
    firebase.db
    .collection('calculator')
    .doc('fair')
    .collection('margins')
    .doc('coins')
    .update(data)

    alert('Atualizado com sucesso')
  }

  return (
    <div className={styles.listCoins}>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={6}>
          <Paper className={styles.paper}>
            <div className='px-8 text-lg'>Custo</div>
            <Form onSubmit={onSubmitCosts} coins={costs} />
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper className={styles.paper}>
            <div className='px-8 text-lg'>Margem</div>
            <Form onSubmit={onSubmitMargin} coins={margins} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
} 